import Vue, { KeepAlive } from 'vue'
import VueRouter from 'vue-router'



// import store from './store'
// import Login from '../components/Login.vue'
// import Home from '../components/Home.vue'
// import Welcome from '../components/Welcome.vue'

// import Users from '../components/user/Fence.vue'
// import Rights from '../components/power/Rights.vue'
// import Roles from '../components/power/Roles.vue'

// import Cate from '../components/goods/Cate.vue'
// import Params from '../components/goods/Params.vue'

// import GoodsList from '../components/goods/Device.vue'
// import Add from '../components/goods/Add.vue'

// import Order from '../components/order/Order.vue'
// import Report from '../components/report/Report.vue'

// 路由懒加载
const Login = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Login.vue')
const Home = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Home.vue')
const Welcome = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/Welcome.vue')


// TODO 跳到直播实时画面
const OnlieHome = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/OnlieHome.vue')
const CesiumOnlinePange = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/cesiumMap/CesiumOnlineMap.vue')
// 2d 模式
const PigeonRacingPlatform2D = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/cesiumMap/pigeonRacingPlatform2D.vue')
// 3d 模式
const PigeonRacingPlatform3D = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/cesiumMap/pigeonRacingPlatform3D.vue')
// 赛鸽轨迹数据
const TrackingData = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/cesiumMap/trackingData.vue')
// 测试 实时轨迹
const TestMaP = () => import( '../components/cesiumMap/testMap.vue')
// testlinemap 测试轨迹线
const TestLineMap = () => import('../components/cesiumMap/testLineMap.vue')
// testLineGMap 测试轨迹线Google
const TestLineGMap = () => import('../components/cesiumMap/testLineGMap.vue')
// 测试谷歌地图
const GoogleMap2D = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '../components/cesiumMap/googleMap2D.vue')

const Users = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/user/User.vue')
const UserBind = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/gpsUser/userBind/UserBind.vue')
const UserList = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/gpsUser/userList/List.vue')
const DeviceList = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/device/deviceList/Device.vue')
const DeviceFence = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/device/deviceFence/Fence.vue')
const PortConfig = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/device/deviceList/PortConfig')
const Dept = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/dept/Dept.vue')
const Code = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/code/Code.vue')
const Customer = () => import('../components/customer/Customer')
const Tracking = () => import(/* webpackChunkName: "Users_Rights_Roles" */ '../components/device/tracking')
const History = () => import('../components/device/history')
const AlterReport = () => import('../components/report/alterReport')
const SpeedReport = () => import('../components/report/speedReport')
const OdometerReport = () => import('../components/report/odometerReport')
const BikeReport = () => import('../components/report/bikeReport')
const HistoryReport = () => import('../components/report/historyReport')
const StopReport = () => import('../components/report/stopReport')
const OilReport = () => import('../components/report/oilReport')
const ReportMain = () => import('../components/report/reportMain')
const CircularFence = () => import('../components/device/deviceFence/circularFence')
const PolygonalFence = () => import('../components/device/deviceFence/polygonalFence')
const SystemMain = () => import('../components/system/systemMain')
const PigeonHouse = () => import('../components/competition/PigeonHouse')
const CompetitionDevice = () => import('../components/competition/CompetitionDevice')
// const systemMapGoogle = () => import('../components/device/systemMapGoogle')


Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/device/tracking', component: Tracking },
  { path: '/device/historicalTrack', component: History },
  { path: '/device/circularFence', component: CircularFence },
  { path: '/device/polygonalFence', component: PolygonalFence },
  { path: '/device/soldDevice', component: () => import('@/components/device/deviceList/SoldDevice') },
  { path: '/device/flightTraining', component: () => import('@/components/device/flightTraining') },
  { path: '/competition/pigeonHouse', component: PigeonHouse },
  { path: '/competition/competitionDevice', component: CompetitionDevice },

  { path: '/pigeonRacingPlatform2D', component: PigeonRacingPlatform2D },
  { path: '/pigeonRacingPlatform3D', component: PigeonRacingPlatform3D ,meta:{keepAlive:true}},
  { path: '/track', component: TrackingData, meta: { title: '轨迹数据' }},

 { path: '/testMap', component: TestMaP },
 { path: '/testLineMap', component: TestLineMap },
 { path: '/testLineGMap', component: TestLineGMap },
 { path: '/gmap2D', component: GoogleMap2D },

 

  { path: '/home',
    component: Home,
    redirect: '/Welcome',
    children: [
      { path: '/home', component: Welcome },
      { path: '/onlieHome', component: OnlieHome },
      { path: '/competition/cesiumMap', component: CesiumOnlinePange },
     

      { path: '/system/users', component: Users },
      { path: '/system/dept', component: Dept },
      { path: '/system/code', component: Code },
      { path: '/system/customer', component: Customer },
      { path: '/gpsUser/userBind', component: UserBind },
      { path: '/gpsUser/userList', component: UserList },
      { path: '/device/deviceList', component: DeviceList },
      { path: '/competition/competitionList', component: () => import('@/components/competition/CompetitionList') },
      { path: '/pigeon/pigeonGuild', component: () => import('@/components/pigeon/PigeonGuild') },
      { path: '/device/deviceFence', component: DeviceFence },
      { path: '/device/portConfig', name: 'portConfig', component: PortConfig },
      { path: '/configMapGmap/configMapGmap', component: () => import('@/components/configMapGmap/configMapGmap') },

      { path: '/device/deviceStatistic/reportMain',
      
        component: ReportMain,
        redirect: '/device/deviceStatistic/odometerReport',
        children: [
          { path: '/device/deviceStatistic/alterReport', component: AlterReport },
          { path: '/device/deviceStatistic/speedReport', component: SpeedReport },
          { path: '/device/deviceStatistic/odometerReport', component: OdometerReport },
          { path: '/device/deviceStatistic/bikeReport', component: BikeReport },
          { path: '/device/deviceStatistic/historyReport', component: HistoryReport },
          { path: '/device/deviceStatistic/stopReport', component: StopReport },
          { path: '/device/deviceStatistic/oilReport', component: OilReport }
        ]
      },
      { path: '/system/systemMain',
        component: SystemMain,
        redirect: '/system/sub/users',
        children: [
          { path: '/system/sub/users', component: Users },
          { path: '/system/sub/dept', component: Dept },
          { path: '/system/sub/customer', component: Customer }
        ]
      }
    ] }

]

const router = new VueRouter({
  routes
})
// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {

   // 修改网页标题
   if (to.meta.title) {

    document.title = to.meta.title;
  } else {
    // document.title = '123';
  }

  // TODO 放行移动端3D地图 赛事3D平台
  if (to.path === '/pigeonRacingPlatform3D') return next()
    if (to.path === '/pigeonRacingPlatform2D') return next()
      if (to.path === '/testMap') return next()
        if (to.path === '/track') return next()
          if (to.path === '/testLineMap') return next()
            if (to.path === '/testLineGMap') return next()
            if (to.path === '/gmap2D') return next()
  // 访问登录页，放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) return next('/login')
  next()
})

export default router
