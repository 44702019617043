export const login = {
    btn: {
        login: '登入',
        experience: '體驗'
    },
    loginMode: {
        userName: '用戶名',
        deviceImei: '設備ID'
    },
    msg: {
        rules: {
            userName: {
                required: '請輸入用戶名',
                length: '長度為4-12個字符'
            },
            password: {
                required: '請輸入口令',
                length: '口令長度6-18個字符'
            }
        },
        login: {
            success: '登入成功'
        }
    }
}

export const home = {
    page: {
        head: {
            menu: {
                home: '主頁',
                mapCenter: '即時地圖',
                deviceTitle: 'GPS 設備',
                deviceList: '設備列表',
                userBind: '用戶設備綁定',
                reportTitle: '統計報表',
                reportMain: 'Gps 報表',
                systemMain: '系統管理',
                systemTitle: '系統管理',
                users: '系統用戶',
                dept: '部門管理',
                code: '代碼管理',
                customer: '客戶管理',
                competitionManage: '賽事管理',
                PigeonGuild: '鴿會管理'
            },
            welcome: '歡迎！',
            changePasswd: '更改密碼',
            logout: '退出'
        },
        diagTitle: '修改口令',
        changePasswordForm: {
            oldPassword: '舊密碼',
            newPassword1: '新密碼',
            newPassword2: '再輸一遍'
        },
        pwdBtnConfirm: '確認',
        pwdBtnCancel: '取消'
    },
    rules: {
        oldPassword: {
            required: '請錄入舊密碼'
        },
        newPassword1: {
            required: '請錄入新密碼'
        },
        newPassword2: {
            required: '請再錄一遍新密碼'
        }
    },
    msg: {
        pwdDiff: '兩次口令不相同！',
        changedSuccess: '口令已更改！'
    }
}

export const welcome = {
    page: {
        left: {
            deviceTotal: '總數:',
            deviceOffline: '離線:',
            deviceOnline: '在線:',
            deviceRun: '在開:',
            searchPlaceholder: '請輸入設備編號',
            searchPlaceholder2: '鸽环号/排名',
            labelAll: '全部',
            labelOnline: '在線',
            labelOffline: '離線',
            realTimeTracking: '實時跟蹤',
            more: '更多',
            historicalTrack: '歷史軌跡',
            sendCommand: '發送指令',
            circularFence: '圓形電子圍欄',
            polygonalFence: '多邊形電子圍欄',
            detailList: '詳細信息',
            operation: ' 操 作',
            flightTraining: '訓飛',
            allSelect: '全選',
            noSelect: '全不選'
        },
        main: {
            refreshPre: '',
            refreshNext: '秒後刷新'
        },
        sendCommand: {
            title: '發送指令',
            stop: '停車',
            resume: '恢復',
            openDoor: '開門',
            closeDoor: '關門',
            start: '啟動',
            stopAfter: '停車(10km後)',
            photograph: '拍照',
            timeInterval: '設置跟蹤時間間隔',
            timeIntervalPlaceholder: '請錄入時間間隔',
            timeZone: '設置時區',
            timeZonePlaceholder: '請錄入時區',
            setAngle: '設置跟蹤角度',
            setAnglePlaceholder: '請錄入跟蹤角度',
            setDistance: '設置跟蹤距離',
            setDistancePlaceholder: '請輸入距離',
            setWiretapping: '設置監聽',
            setWiretappingPlaceholder: '請錄入監聽電話',
            setSpeed: '設置速度限製',
            setSpeedPlaceholder: '請錄入速度限製',
            movementAlert: '設置移動警報',
            movementAlertPlaceholder: '錄入移動距離',
            authorizedPhone: '設置授權電話',
            authorizedPhonePlaceholder: '選擇按鈕',
            phoneSMSPlaceholder: '請錄入SMS號碼',
            phoneCALLPlaceholder: '請錄入CALL號碼',
            setRFID: '設置RFID',
            sendMessage: '發送消息',
            closeBtn: '關閉'
        },
        deviceInfo: {
            title: '設備信息'
        }
    },
    msg: {
        sendCmdSuccess: '指令發送成功！'
    }
}

export const competition = {
    name: '比賽名稱',
    date: '比賽日期',
    createTime: '創建日期',
    searchCompetition: '搜索比賽',
    createCompetition: '創建比賽',
    expiryDate: '有效日期',
    pigeonGuildName: '鴿會名稱',
    pigeonHouseNo: '鴿舍號',
    startLat: '起點經度',
    startLon: '起點緯度',
    endLat: '終點經度',
    endLon: '終點緯度',
    selectGuild: '請選擇鴿會',
    manageDevice: '管理設備',
    msg: {
        inputNamePrompt: '請輸入比賽名稱',
        selectDate: '請選擇比賽日期',
        createSucceed: '比賽創建成功',
        deleteTip: '請確認是否刪除比賽'
    },
    btns: {
        search: '搜索',
        create: '創建',
            check:'查看',
     checkTrajectory:'查看'
    }
}

export const pigeonGuild = {
    searchPigeonGuild: '搜索鴿會',
    createPigeonGuild: '創建鴿會',
    editPigeonGuild: '編輯鴿會',
    pigeonGuildName: '鴿會名稱',
    pigeonGuildContact: '鴿會聯系人',
    pigeonGuildTel: '鴿會電話',
    pigeonGuildAddress: '鴿會地址',
    remark: '備註',
    pigeonGuildPassword: '密碼',
    passwordConfirm: '確認密碼',
    msg: {
      inputNamePrompt: '請輸入鴿會名稱',
      inputContactPrompt: '請輸入鴿會聯系人',
      inputTelPrompt: '請輸入鴿會電話',
      inputAddressPrompt: '請輸入鴿會地址',
      inputRemarkPrompt: '請輸入備註',
      inputPasswordPrompt: '請輸入長度為4-16個字符的密碼(不輸入默認123456)',
      hintPasswordConfirm: '請輸入確認密碼',
      deleteTip: '請確認是否刪除該鴿會,請謹慎選擇，刪除後會將該賬號相關的數據信息全部清除'
    },
    btns: {
      search: '搜索',
      create: '創建'
    }
  }
  
  export const pigeonHouse = {
    pigeonNo: '鴿環號',
    imei: '設備號',
    addPigeonNo: '添加鴿環號',
    editPigeonNo: '編輯鴿環信息',
    createPigeonHouse: '創建鴿舍',
    editPigeonHouse: '編輯鴿舍',
    pigeonHouseNo: '會員編號',
    pigeonHouseName: '姓名',
    pigeonHousePhone: '電話後五位',
    pigeonHouseContact: '鴿舍聯系人',
    pigeonHouseTel: '鴿舍電話',
    pigeonHouseAddress: '鴿舍地址',
    pigeonElectronicNo: '電子環',
    pigeonRemark: '失格',
    pigeonHouseCount: '鴿舍數量',
    pigeonRingCount: '鴿環數量',
    msg: {
      inputPrompt: '請輸入鴿環號或設備號',
      inputSearchPrompt: '請輸入會員編號或姓名',
      inputPigeonNoPrompt: '請輸入鴿環號',
      inputNoPrompt: '請輸入會員編號',
      inputNamePrompt: '請輸入姓名',
      inputPhonePrompt: '請輸入電話後五位',
      inputContactPrompt: '請輸入鴿舍聯系人',
      inputTelPrompt: '請輸入鴿舍電話',
      inputAddressPrompt: '請輸入鴿舍地址',
      inputPasswordPrompt: '請輸入長度為4-16個字符的密碼(不輸入默認電話後五位)',
      inputAddressPrompt: '請輸入鴿舍地址',
      inputElectronicNoPrompt: '請輸入電子環',
      inputRemarkPrompt: '請輸入失格',
      importPrompt: '正在導入中',
      deleteHouseTip: '請確認是否刪除該鴿舍,請謹慎選擇，刪除後會將該賬號相關的數據信息全部清除',
      deleteTip: '請確認是否刪除該鴿環'
    },
    btns: {
      importFile: '導入',
      add: '添加',
      bind: '綁定'
    }
  }
  
  export const competitionDevice = {
    searchDevice: '搜索設備',
    addDevice: '添加設備',
    msg: {
      inputImeiPrompt: '請輸入設備號',
      deleteTip: '請確認是否在比賽中取消該設備'
    }
  }

export const device = {
    createTime: '激活時間',
    deviceImei: 'IMEI',
    deviceName: '設備名稱',
    nickname: '昵稱',
    organization: '所屬車隊',
    deviceType: '設備類型',
    deptId: '部門',
    bindMobile: 'Sim卡號',
    version: '設備版本',
    timeFromPreviousPoint: '距上一點時間',
    distanceFromPreviousPoint: '距上一點距離',
    deviceShortNumber: '設備短號',
    deviceFamilyNumber: '家庭電話',
    deviceRemark: '備註',
    lastLongitude: '經度',
    lastLatitude: '緯度',
    weather: '天氣',
    lastLocationTime: '時間',
    lastLocationType: '定位類型',
    lastSpeed: '速度',
    lastMotionStatus: '運動狀態',
    lastDeviceVol: '設備電量',
    heading: '朝向',
    odometer: '里程',
    onlineStatus: '在線狀態',
    accStatus: '引擎',
    expireDate: '過期時間',
    lastDeviceSms: '設備信息',
    lastPositionDesc: '位置描述',
    plate: '車牌',
    timeZone: '時區',
    baseMileage: '基礎里程',
    deviceColour: '顏色',
    deviceStatus: '設備狀態',
    positioningTime: '定位時間',
    uploadTime: '上傳時間',
    altitude: '海拔高度',
    locationPoints: '定位點數',
    lost: '飛丟',
    available: '可用',
    ad1: '油箱1容量',
    ad1Max: '油箱1最大Ad',
    ad1Min: '油箱1最小Ad',
    ad2: '油箱2容量',
    ad2Max: '油箱2最大Ad',
    ad2Min: '油箱2最小Ad',
    ad3: '油箱3容量',
    ad3Max: '油箱3最大Ad',
    ad3Min: '油箱3最小Ad',
    ad4: '油箱4容量',
    ad4Max: '油箱4最大Ad',
    ad4Min: '油箱4最小Ad',
    fuel: '油量',
    address: '地址',
    temperature: '溫度',
    displayedStatus: '狀態',
    alarm: '警報',
    deviceStatusEnum: {
        online: '在線',
        offline: '離線'
    },
    deviceTypeEnum: {
        vehicleMountedLocator: '車載定位器',
        pigeonLocator: '鴿子定位器',
        studentCard: '學生卡',
        drivingRecorder: '行車記錄儀'
    },
    msg: {
        inputImei: '請輸入設備的IMEI號',
        unbindTip: '請確認是否解綁該設備',
        imeiRule: '設備IMEI號是由15到17位的純數字組合而成的,請輸入設備IMEI號後六位或全部的設備IMEI號'
    }
}

export const user = {
    userName: '用戶名稱',
    email: '電子郵箱',
    mobile: '手機',
    sex: '性別',
    userRole: '用戶角色',
    status: '狀態',
    createTime: '創建時間',
    expireDate: '過期時間',
    password: '口令'
}

export const dept = {
    deptName: '部門名稱',
    createTime: '創建時間'
}

export const customer = {
    customerName: '客戶名稱',
    contactPhone: '聯系電話',
    contactUser: '聯系人',
    customerType: '客戶類型',
    address: '地址',
    delFlag: '刪除標識',
    quota: '設備限額',
    createTime: '創建時間',
    pCustomerName: '父公司'
}

export const breadCrumb = {
    mainPage: '主頁',
    sysUser: '系統用戶',
    userList: '用戶列表',
    sysDept: '部門',
    deptList: '部門列表',
    gpsDevice: '定位設備',
    deviceList: '設備列表',
    inOutConfig: '輸入輸出配置',
    sysManage: '系統管理',
    codeManage: '代碼管理',
    customer: '客戶',
    customerList: '客戶列表',
    userDeviceBind: '用戶設備綁定',
    reportMain: '報表主頁',
    reportAlter: '警報報表',
    reportBike: '單車報表',
    reportOdometer: '旅程報表',
    reportSpeed: '超速報表',
    historyReport: '歷史報表'
}

export const common = {
    bind: '綁定',
    unbind: '解綁',
    bindDevice: '綁定設備',
    unbindDevice: '解綁設備',
    dealer: '經銷商',
    search: '搜索',
    searchDevice: '搜索設備',
    reset: '重置',
    add: '添加',
    confirm: '確認',
    cancel: '取消',
    notice: '提示',
    bindSucceed: '綁定成功',
    bindFailed: '綁定失敗',
    unbindSucceed: '解綁成功',
    unbindFailed: '解綁失敗',
    createSuccess: '添加成功',
    updateSuccess: '更新成功',
    deleteSuccess: '刪除成功',
    updateFailed: '更新失敗',
    createFailed: '添加失敗',
    deleteFailed: '刪除失敗',
    sn: '序號',
    filter: '篩選',
    filterDevice: '篩選設備',
    operation: '操作',
    canceled: '已取消!',
    deleteConfirm: '此操作將永久刪除此數據，是否繼續？',
    close: '關閉',
    select: '選取',
    selectDeviceTile: '選擇設備',
    selectDate: '選擇日期',
    manage: '管理',
    edit: '編輯',
    delete: '刪除',
    reminder: '溫馨提示'
}

export const userPage = {
    userNamePlaceholder: '請輸入用戶名',
    userStatusPlaceholder: '請選擇狀態',
    userMobilePlaceholder: '請輸入手機號碼',
    sexPlaceholder: '請選擇性別',
    userRolePlaceholder: '請選擇用戶角色',
    statusPlaceholder: '請選擇狀態',
    deptPlaceholder: '請選擇部門',
    customerPlaceholder: '請選擇客戶',
    expireDatePlaceholder: '請選擇過期日期',
    rules: {
        email: {
            legalEmail: '請輸入合法郵箱'
        },
        mobile: {
            legalMobile: '請輸入合法手機號碼',
            required: '請輸入手機號碼'
        },
        deptId: {
            required: '請選擇部門'
        },
        customerId: {
            required: '請選擇客戶'
        },
        password: {
            required: '請輸入密碼',
            length: '密碼長度為4-10個字符'
        },
        userName: {
            required: '請輸入用戶名稱',
            length: '用戶名稱4-10個字符'
        },
        userType: {
            required: '請選擇用戶類型'
        },
        expireDate: {
            threeMonth: '3個月',
            sixMonth: '半年',
            oneYear: '一年'
        },
        userRole: {
            required: '需要選擇一個用戶角色'
        }
    }
}

export const deptPage = {
    deptNamePlaceholder: '請輸入部門',
    addTitle: '添加部門',
    rules: {
        deptName: {
            required: '請輸入部門名稱',
            length: '部門名稱長度為4-30個字符'
        },
        customerId: {
            required: '請選擇公司'
        }
    }
}

export const code = {
    codeName: '代碼名稱',
    codeClass: '代碼類型',
    codeValue: '代碼值',
    codeDesc: '代碼描述'
}

export const codePage = {
    codeClassPlaceholder: '請輸入代碼類型',
    codeNamePlaceholder: '請輸入代碼名稱',
    rules: {
        codeClass: {
            required: '請輸入代碼類型',
            length: '代碼類型長度為3-30個字符'
        },
        codeName: {
            required: '請輸入代碼名稱'
        },
        codeValue: {
            required: '請輸入代碼值'
        }
    }
}

export const customerPage = {
    customerNamePlaceholder: '請輸入客戶名稱',
    customerStatusPlaceholder: '請選擇客戶狀態',
    customerTypePlaceholder: '選擇客戶類型',
    addDiagTitle: '添加/編輯 客戶',
    rules: {
        customerName: {
            required: '請輸入客戶名稱',
            length: '客戶名稱長度4-30個字符'
        },
        customerType: {
            required: '請選擇客戶類型'
        },
        quota: {
            required: '請輸入設備限額',
            type: '請輸入數字',
            quotaMin: '必須大於 0'
        }
    }
}

export const devicePage = {
    deviceImeiPlaceholder: '請輸入設備編號',
    bindMobilePlaceholder: '請輸入Sim卡號',
    deviceNamePlaceholder: '請輸入設備名稱',
    organizationPlaceholder: '請輸入車隊名稱',
    deviceTypePlaceholder: '請選擇設備類型',
    deptPlaceholder: '選擇一個部門',
    addDiagTitle: '添加/修改設備',
    tankDiagTitle: '修改油箱配置',
    realTimeTrack: '跟蹤',
    historicalTrack: '軌跡',
    trackPlayback:'軌跡回放',
    sendCommand: '指令',
    config: '配置',
    configuration: '配置信息',
    getDeviceInfo: '信息',
    flightTraining: '訓飛',
    option: {
        title: '操作菜單',
        edit: '編輯',
        config: '輸入/輸出配置',
        tank: '油箱配置',
        remove: '刪除'
    },
    rules: {
        bindMobile: {
            required: 'Sim卡號不能為空'
        },
        deviceImei: {
            required: '設備ID不能為空'
        },
        deviceName: {
            required: '設備名稱不能為空'
        },
        deptId: {
            required: '部門不能為空'
        },
        expireDate: {
            threeMonth: '3個月',
            sixMonth: '半年',
            oneYear: '一年'
        },
        timeZone: {
            required: '時區不能為空',
            typeCheck: '時區必須是數字',
            validator: '時區必須在-720到720之間'
        },
        baseMileage: {
            typeCheck: '基礎里程必須是數字r',
            validator: '基礎里程必須不小於0'
        },
        insert: {
            imeiExists: '設備ID已存在!',
            nameExists: '設備名詞已存在!'
        }
    },
    msg: {
        deleteFlightTrainingPrompt: '是否刪除此訓飛記錄'
    }
}

export const portConfigPage = {
    itemName: '配置項',
    portName: '輸入輸出端口',
    value: '值',
    configType: '配置類型',
    itemNamePlaceholder: '請選擇配置項',
    portNamePlaceholder: '請選擇輸入輸出端口',
    configTypePlaceholder: '請選擇配置類型',
    addFromTitle: '添加/編輯輸入輸出配置',
    option: {
        remove: '刪除'
    },
    rules: {
        itemName: {
            required: '請選擇配置項'
        },
        portName: {
            required: '請選擇輸入輸出端口'
        },
        value: {
            required: '請輸入對應的值'
        },
        configType: {
            required: '請選中配置類型'
        }
    },
    msg: {
        checkData: '控製指令必須配置為輸出端口，狀態必須配置為輸入端口'
    }
}

export const userBind = {
    mainControl: '主控',
    mainControlPlaceholder: '是否主控',
    createTime: '綁定時間',
    remark: '備註',
    addFromTitle: '添加用戶設備綁定',
    msg: {
        userName: '必須錄入用戶名',
        deviceImei: '必須錄入設備編號'
    }
}

export const history = {
    play: '播放',
    stop: '停止',
    slow: '慢',
    fast: '快',
    pause: '暫停',
    continue: '繼續',
    acc: '引擎'
}

export const circularFence = {
    fenceName: '圍欄名稱',
    radius: '半徑',
    inOut: '進/出',
    latitude: '緯度',
    longitude: '經度',
    addFenceTitle: '添加圓形圍欄',
    addFence: '添加圍欄',
    query: '經緯度查詢',
    rules: {
        fenceName: {
            required: '請錄入圍欄名稱'
        },
        radius: {
            required: '請錄入圍欄半徑'
        },
        inOut: {
            required: '請選擇進出類型'
        },
        selectPoint: '請在地圖上選擇一個點',
        latLng: '請輸入經緯度',
        lat: '緯度在 -90到 90之間',
        lng: '經度在 -180 到 180之間'
    }
}

export const polygonalFence = {
    fenceName: '圍欄名稱',
    inOut: '進/出',
    query: '經緯度查詢',
    addFence: '添加圍欄',
    latitude: '緯度',
    longitude: '經度',
    rules: {
        fenceName: {
            required: '請輸入圍欄名稱'
        },
        inOut: {
            required: '請選擇進/出類型'
        }
    }
}

export const mapJs = {
    fenceName: '圍欄名稱',
    radius: '半徑',
    inOut: '進/出'
}

export const reportMain = {
    gpsReport: 'GPS報表',
    odometerItem: '旅程報表',
    alterItem: '警報報表',
    overSpeedItem: '超速報表',
    bikeItem: '單車報表',
    historyItem: '歷史報表',
    stopItem: '停車報表',
    oilItem: '油量報表'
}

export const odometerReport = {
    query: {
        deviceImei: '設備IMEI',
        deviceImeiPlaceholder: '請輸入設備IMEI',
        startTimePlaceholder: '開始時間',
        endTimePlaceholder: '結束時間'
    },
    reportDate: '報告日期',
    deviceImei: '設備IMEI',
    deviceName: '設備名稱',
    tripStartDate: '旅程開始',
    tripEndDate: '旅程結束',
    odometer: '里程',
    oilConsumption: '油耗',
    avgSpeed: '平均速度',
    maxSpeed: '最大速度'
}

export const alterReport = {
    query: {
        deviceImei: '設備IMEI',
        deviceImeiPlaceholder: '請輸入設備IMEI',
        startTimePlaceholder: '開始時間',
        endTimePlaceholder: '結束時間'
    },
    alarmTime: '報警時間',
    deviceImei: '設備IMEI',
    deviceName: '設備名稱',
    alarmType: '警報類型',
    fenceName: '圍欄名稱',
    fenceType: '圍欄類型',
    alarmValue: '警報閥值',
    currentValue: '報警時值'
}

export const overSpeedReport = {
    query: {
        deviceImei: '設備IMEI',
        deviceImeiPlaceholder: '請輸入設備IMEI',
        startTimePlaceholder: '開始時間',
        endTimePlaceholder: '結束時間'
    },
    alarmTime: '報警時間',
    deviceImei: '設備IMEI',
    deviceName: '設備名稱',
    alarmValue: '警報速度',
    currentValue: '當時速度'
}

export const historyReport = {
    query: {
        deviceImei: '設備ID',
        deviceImeiPlaceholder: '請輸入設備ID',
        startTimePlaceholder: '起始時間',
        endTimePlaceholder: '截止時間'
    },
    gpsTime: '定位時間',
    deviceImei: '設備ID',
    deviceName: '車牌',
    lng: '經度',
    lat: '緯度',
    heading: '方向',
    speed: '速度',
    odometer: '里程',
    ad: '油量',
    accStatus: '引擎狀態',
    alarm: '警報',
    temperature: '溫度'
}

export const stopReport = {
    query: {
        deviceImei: '設備ID',
        deviceImeiPlaceholder: '請輸入設備ID',
        startTimePlaceholder: '起始時間',
        endTimePlaceholder: '截止時間'
    },
    startTime: '開始時間',
    endTime: '結束時間',
    deviceImei: '設備ID',
    deviceName: '車牌',
    lng: '經度',
    lat: '緯度',
    duration: '時長'
}

export const oilReport = {
    query: {
        deviceImei: '設備ID',
        deviceImeiPlaceholder: '請輸入設備ID',
        startTimePlaceholder: '起始時間',
        endTimePlaceholder: '截止時間'
    },
    gpsTime: '定位時間',
    deviceImei: '設備ID',
    deviceName: '車牌',
    lng: '經度',
    lat: '緯度',
    heading: '方向',
    speed: '速度',
    odometer: '里程',
    ad: '油量',
    accStatus: '引擎狀態',
    alarm: '警報',
    temperature: '溫度'
}

export const bikeReport = {
    query: {
        deviceImei: '設備IMEI',
        deviceImeiPlaceholder: '請輸入設備IMEI',
        startTimePlaceholder: '開始時間',
        endTimePlaceholder: '結束時間'
    },
    tripDate: '旅行時間',
    deviceImei: '設備IMEI',
    humanRiding: '人力騎行',
    pasRiding: '助力騎行',
    tripOdometer: '里程',
    sectionCalorie: '本次卡路里',
    accumulateCalorie: '累計卡路里'
}

export const unit = {
    second: '秒',
    minute: '分鐘',
    hour: '小時',
    meter: '米',
    kilometer: '公里',
    speedPerMinute: '分速',
    speedPerHour: '時速',
    averageSpeedPerMinute: '平均分速',
    averageSpeedPerHour: '平均時速',
    meterPerSecond: '米/秒',
    meterPerMinute: '米/分鐘',
    kilometerPerHour: '公里/小時',
    minutePerTime: '分鐘/次'
}

export const config = {
    standbyDatetime: '開始待機時間',
    cst: '配置數據修改時間',
    rsut: '設定預約開機時間',
    rgli: '定位頻率',
    rdui: '上傳頻率',
    lpl: '低電量門限',
    lpgli: '低電量模式定位頻率',
    lpdui: '低電量模式上傳頻率',
    nmst: '第二段開始時間',
    nmet: '第二段結束時間',
    ngli: '第二段定位頻率',
    ndui: '第二段數據上傳頻率',
    cgli: '第三段定位頻率',
    cdui: '第三段數據上傳頻率',
    rdut: '數據上傳啟動時間',
    gddt: '開始下載星歷時間',
    rsud: '比賽延遲開始時間'
}

export const flightTraining = {
    record: '訓飛記錄',
    standbyDatetime: '開始待機時間',
    cst: '配置數據修改時間',
    firstLocationTime: '第一次定位時間',
    endTime: '結束時間',
    validEndTime: '有效結束時間',
    lastLogTime: '最後日誌時間',
    pointCount: '點數',
    validPointCount: '有效點數',
    totalLocationDuration: '定位總時長',
    totalStandbyDuration: '待機總時長',
    tip: {
        updateValidEndTime: '修改有效結束時間',
        selectValidEndTime: '請選擇有效結束時間'
    }
}

export const locationType = {
    invalid: '無效定位',
    gps: 'GPS定位',
    baseStation: '2G/4G基站定位',
    NBIOT: 'NB基站定位'
}

export const deviceType = {
    vehicle: '車載定位器',
    pigeon: '鴿子定位器',
    studentCard: '學生卡',
    recorder: '行車記錄儀'
}

export const errorCode = {
    requestError: '請求失敗,請稍後重試', // errorCode: -1
    internalError: '內部錯誤,請聯系管理員', // errorCode: 1000
    tokenExpired: 'Token已過期,請重新登入', // errorCode: 2004
    accountNotExistOrPasswordError: '賬號或密碼錯誤', // errorCode: 3000
    userNotExist: '用戶不存在', // errorCode: 3001
    deviceNotExist: '設備不存在', // errorCode: 3002
    oldPasswordError: '舊密碼錯誤', // errorCode: 3006
    userExpired: '用戶登入已過期', // errorCode: 3007
    deviceImeiExisted: '設備IMEI已存在', // errorCode: 3008
    deviceNameExisted: '設備名稱已存在', // errorCode: 3009
    accountExisted: '賬號已存在', // errorCode: 3010
    passwordError: '密碼錯誤', // errorCode: 3011
    deviceBound: '該設備已被綁定,不允許重復綁定', // errorCode: 3013
    noAuthorizationToUnbind: '此設備為鴿棚專案,不允許解綁', // errorCode: 3015
    noAuthorizationToOperate: '對不起,您無權操作此設備', // errorCode: 5006
    verifyFailed: '驗證碼錯誤', // errorCode: 21000
    authorizationCodeExpired: '驗證碼已過期,請重新獲取' // errorCode: 21001
}

// TODO computer 电脑端 直播列表多语言文字

export const computerLanguage = {

    // 直播模式 
    liveMode: '直播模式',
    // 軌迹模式
    trackMode: '軌迹模式',
     // 雷達模式
     radarMode: '雷達模式',
  
  
    // 全選 全不選 
    selectAll: '全選',
    unSelectAll: '全不選',
    pigeonNum: '賽鴿數量',
  
  // 切換 播放 暫停 重置 加速 減速 數據 梯隊數據 賽鴿排名
    switch: '切換',
    play: '播放',
    pause: '暫停',
    reset: '重置',
    accelerate: '加速',
    decelerate: '減速',
    data: '數據',
    teamData: '梯隊數據',
    pigeonRanking: '賽鴿排名',
  
    //軌迹數據 軌迹詳情
    trackData: '軌迹數據',
    trackDetail: '軌迹詳情',
  
    startPoint: '起點',
    endPoint: '終點',
    kilometer: '公裏',
    aboveKilometer: '公裏以上',
  
    ranking: '排名',
    pigeonRingNumber: '鴿環號',
    nickname: '昵稱',
    speed: '速度',
    altitude: '海拔',
    distanceToDestination: '距離終點',
    odometer: '裏程',
    accumulateTime: '累積時間',
    stayTime: '停留時間',
  
    // 分段資料 
    segmentData: '分段資料',
    // 時間
    time: '時間',
    distance_m: '距離(m)',
    speed_m_s: '速度(m/s)',
    speed_m_min: '速度(m/min)',
    altitude_m: '海拔(m)',
    distanceToDestination_km: '距離終點(km)',
    deviceVol: '電量',
  
    // 當前設備 起點時間 終點時間 飛行時長
    currentDevice: '當前設備',
    startPointTime: '起點時間',
    endPointTime: '終點時間',
    flightDuration: '飛行時長',
  
    // 平均分速 最高分速 平均高度 最大高度 實際距離 實際分速 直線距離 直線分速
    averageSpeed_m_min: '平均分速(m/min):',
    maxSpeed_m_min: '最高分速(m/min):',
    averageAltitude_m: '平均高度(m):',
    maxAltitude_m: '最大高度(m):',
    actualDistance_km: '實際距離(km):',
    actualSpeed_m_min: '實際分速(m/min):',
    straightLineDistance_km: '直線距離(km):',
    straightLineSpeed_m_min: '直線分速(m/min):'

  
  }


// TODO 台湾 手机端多语言文字
export const phoneLanguage  = {
    loading: '正在加載,請耐心等待...',

    nodate: '暫無數據',
    eventList: '賽事列表',
    selectYear: '請選擇年',
    selectPigeonHome: '請選擇鴿舍',
    inputPigeonRingNumber: '请輸入鴿環號',
    live: '直播中',
    pigeonsList: '鴿子列表',
    pigeonHomeList: '鴿舍列表',
    selectedData: '已選數據',
    clearAll: '全部清除',
    pigeonHome: '鴿舍',
    trackCertificate: '軌迹證書',

    matchName: '比賽名稱',
    matchDate: '比賽日期',
    matchTime: '比賽時間',
    operation: '操作',

    view: '查看',
    delete: '刪除',
    edit: '編輯',

    inputMatchSearchHint: '請輸入比賽名稱',
    search: '搜索',
    cancel: '取消',
    confirm: '確認',

      // 返回赛事
    selectedData: '已選數據：',
    strip: '條',
    backToMatch: '返回賽事',
    viewTrajectory: '查看軌迹',
    radarMode: '雷達模式',
    pigeonNum: '鴿子數量',
    inputEnterRankOrpigeonRingNumberSearchHint: '請輸入排名/鴿環號',

    ranking: '排名',
    pigeonRingNumber: '鴿環號',
    nickname: '昵稱',
    speed: '速度',
    altitude: '海拔',
    distanceToDestination: '距離終點',
    odometer: '裏程',
    accumulateTime: '累積時間',
    stayTime: '停留時間',
    pointIndex:'定位點數',

    startPoint: '起點',
    endPoint: '終點',
    kilometer: '公裏',

    time: '時間',
    speed_m_min: '速度(m/min)',
    altitude_m: '海拔(m)',
    distanceToDestination_km: '距離終點(km)',
   deviceVol: '電量',

  }
